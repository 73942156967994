import React from "react";
import CreatePass from "components/createPass";

const DayPassPage = () => {
    
    return (
        <CreatePass 
            title='Boka parkering' 
            description='Skriv in ditt registreringsnummer och giltig kod för att boka din parkering på Rögle-parkeringen.'
            isSeasonTicket={false}
            successMessage='Parkering bokad!'
            />
    );
}

export default DayPassPage;