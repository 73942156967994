import Logo from "components/logo";
import React from "react";
import { Outlet } from "react-router-dom";

export const DefaultLayout = () => {
    return (
        <div className="flex flex-col p-6 items-center justify-center">
            <Logo />
            <Outlet />
        </div>
    )
};

export default DefaultLayout;