import { getRewardDateWinners } from "api/rewards";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const WinnersPage = () => {

    const navigate = useNavigate();
    const [rewardDateWinners, setRewardDateWinners] = useState();
    const [loading, setLoading] = useState();
    const { rewardDateExtId } = useParams();

    useEffect(() => {
        const loadWinners = async () => {
            var response = await getRewardDateWinners(rewardDateExtId)
            setRewardDateWinners(response);
            console.log(response);
        }

        try {
            setLoading(true);
            loadWinners();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [])

    return (
        <div className="flex flex-col max-w-md pt-20">
            <h2 className="text-2xl mb-3">Parkeringschansen</h2>
            <p className="mb-3">Vinstnummer för parkeringschansen</p>
            <div className="flex flex-col shadow pl-3 pr-3 rounded-xl w-full min-w-[375px]">
                <div className="flex flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3 justify-between">
                    <span className="text-left pl-3 font-medium">Vinstnummer</span>
                    <span className="text-left pr-3 font-medium">Plats</span>
                </div>
                {rewardDateWinners && rewardDateWinners.length > 0 && rewardDateWinners.map((winner, iteration) => {
                    return <RewardDateWinnerItem key={iteration} position={winner.position} code={winner.code} />
                })

                }
                {!rewardDateWinners &&
                    <div className="flex flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3 justify-between">
                        <div className="w-24 h-2 ml-3 bg-slate-200 animate-pulse"></div>
                        <div className="w-12 h-2 mr-3 bg-slate-200 animate-pulse"></div>
                    </div>
                }
                {rewardDateWinners && rewardDateWinners.length == 0 &&
                    <div className="flex flex-row items-center h-11">
                        <span>Inga vinnare</span>
                    </div>
                }
            </div>
        </div>
    );
}

const RewardDateWinnerItem = ({ position, code }) => {

    return (
        <div className="flex p-3 flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3 justify-between">
            <span>{code}</span>
            <span>#{position}</span>
        </div>
    );
}

export default WinnersPage;