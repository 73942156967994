import React from "react";
import CreatePass from "components/createPass";

const SeasonPassPage = () => {
    return (
        <CreatePass
            title='Boka säsongsparkering'
            description='Skriv in registreringsnummer och giltig kod för att boka en säsongsparkering på Rögle-parkeringen.'
            isSeasonTicket={true}
            successMessage='Säsongsparkering bokad!'
        />
    );
}

export default SeasonPassPage;