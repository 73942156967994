import React, { useRef, useState } from "react";
import Button from "components/button";
import { createPass } from "api/bookings";
import { ReactComponent as IconCheck } from 'assets/icons/ico_check_white.svg';

export const CreatePass = ({ title, description, successMessage, isSeasonTicket }) => {

    const [pass, setPass] = useState({ licensePlateNumber: '', code: '', isSeasonTicket: isSeasonTicket, phoneNumber: '' });
    const [loading, setLoading] = useState();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState('Något gick fel, försök igen.')
    const successRef = useRef();

    const handleCreatePass = async () => {

        if (!pass.licensePlateNumber || !pass.code) {
            showErrorMessage('Du måste fylla i registeringsnummer och kod.');
            return;
        }

        try {
            setLoading(true);
            const response = await createPass(pass);
            
            if (response.includes('Invalid code')) {
                showErrorMessage('Felaktig kod');
            } else {
                showSuccessMessage();
                setPass({ ...pass, licensePlateNumber: '' });
            }
        } catch(e) {
            console.error(e);
            showErrorMessage('Något gick fel, försök igen');
        } finally {
            setLoading(false);
        }
    }

    const showErrorMessage = (errorMessage) => {
        setError(true);
        setErrorMessage(errorMessage);
        setTimeout(() => { setError(false) }, 4000);
    }

    const showSuccessMessage = () => {
        setSuccess(true);
        setTimeout(() => { setSuccess(false) }, 4000);
    }

    return (
        <div className="flex flex-col max-w-md pt-20">
            <h2 className="text-2xl mb-3">{title}</h2>
            <p className="mb-3">{description}</p>
            <label className="p-1">Registreringsnummer</label>
            <input value={pass.licensePlateNumber} type="text" name="registration-number" autoComplete="off" className="h-11 mb-3 p-2 bg-slate-200 rounded-lg" onChange={(e) => { setPass({ ...pass, licensePlateNumber: e.target.value }) }} placeholder="ABC123"></input>
            <label className="p-1">Kod</label>
            <input value={pass.code} type="text" name="code" autoComplete="off" className="h-11 mb-3 p-2 bg-slate-200 rounded-lg" onChange={(e) => { setPass({ ...pass, code: e.target.value }) }} placeholder="Kod"></input>
            <div className="flex justify-end">
                <Button title={title} onClick={() => { console.log(pass); handleCreatePass(); }} loading={loading}>
                    <IconCheck />
                </Button>
            </div>
            {success &&
                <div ref={successRef} className="flex absolute top-2 w-[448px] items-center justify-center bg-green-500 rounded-lg h-11 mt-6 animate-bounce">
                    <span className="text-white text-base font-medium">{successMessage}</span>
                </div>
            }
            {error &&
                <div ref={successRef} className="flex absolute top-2 w-[448px] items-center justify-center bg-red-500 rounded-lg h-11 mt-6 animate-bounce">
                    <span className="text-white text-base font-medium">{errorMessage}</span>
                </div>
            }
        </div>
    );
}

export default CreatePass;