import React, { useEffect, useState } from "react";
import { ReactComponent as IconNext } from 'assets/icons/ico_next.svg';
import { useNavigate } from "react-router";
import { getRewardDates } from "api/rewards";

const RewardDatesPage = () => {
    const navigate = useNavigate();
    const [rewardDates, setRewardDates] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        const loadRewardDates = async () => {
            var response = await getRewardDates('7mXAMzJNViE');
            setRewardDates(response);
        }

        try {
            setLoading(true);
            loadRewardDates();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [])

    return (
        <div className="flex flex-col max-w-md pt-20">
            <h2 className="text-2xl mb-3">Parkeringschansen</h2>
            <p className="mb-4">Datum för parkeringschansen. Klicka på ett datum för att se vilka lottnummer som vunnit</p>
            <div className="flex flex-col shadow pl-3 pr-3 rounded-xl w-full">
                <div className="flex flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3">
                    <span className="text-left pl-3 font-medium">Datum</span>
                </div>
                
                {rewardDates && rewardDates.map((rewardDate, iteration) => {
                        return (<RewardDateItem key={iteration} date={rewardDate.startDateTime.substring(0,10)} onClick={() => { navigate(rewardDate.rewardDateExtId) }} />)
                    })
                }
                {!rewardDates &&
                    <div className="flex flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3 justify-between">
                    <div className="w-24 h-2 ml-3 bg-slate-200 animate-pulse"></div>
                    <div className="w-12 h-2 mr-3 bg-slate-200 animate-pulse"></div>
                </div>
                }
            </div>
        </div>
    );
}

const RewardDateItem = ({ date, onClick }) => {
    return (
        <button className="flex flex-row items-center h-11 border-b-2 border-slate-200 last:border-b-0 -ml-3 -mr-3 justify-between" onClick={() => { onClick(); }}>
            <span className="pl-3">{date}</span>
            <span className="pr-3">
                <IconNext />
            </span>
        </button>
    );
}

export default RewardDatesPage;