import React from 'react';
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg';

const Logo = ({textColor, className}) => {

    if (!className) {
        className = 'flex items-center';
    } else {
        className = `flex items-center ${className}`;
    }

    if (!textColor) {
        textColor = 'text-2xl pl-4';
    } else {
        textColor = `text-2xl pl-4 ${textColor}`;
    }

    return (
        <div className={className}>
            <AirParkLogo />
            <span className={textColor}>Rögle</span>
        </div>
    );
}

export default Logo;