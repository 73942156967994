import DefaultLayout from 'layouts/defaultLayout';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StartPage from 'pages/start';
import DayPassPage from 'pages/dayPass';
import SeasonPassPage from 'pages/seasonPass';
import WinnersPage from 'pages/winners';
import RewardDatesPage from 'pages/rewardDates';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<StartPage />} />
            <Route path="/boka-parkering" element={<DayPassPage />} />
            <Route path="/boka-sasongsparkering" element={<SeasonPassPage />} />
            <Route path="/parkeringschansen" element={<RewardDatesPage />} />
            <Route path="/parkeringschansen/:rewardDateExtId" element={<WinnersPage />} />
          </Route>
        </Routes>
    </BrowserRouter >
  );
}

export default App;
