const BASE_URL = `${process.env.REACT_APP_API_REWARDS_BASE_URL}/Rewards`

export const getRewardDates = async (rewardExtId) => {
    const response = await fetch(BASE_URL + '/get-reward-dates/' + rewardExtId, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }

  export const getRewardDateWinners = async (rewardDateExtId) => {
    const response = await fetch(BASE_URL + '/get-reward-date-winners/' + rewardDateExtId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        }
      })
    
      if (response.status === 200) {
        return response.json();
      }
    
      throw response;
  }