const BASE_URL = `${process.env.REACT_APP_API_BOOKINGS_BASE_URL}/WhiteList`

export const createPass = async (pass) => {
    const response = await fetch(BASE_URL + '/white-list-car', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(pass)
    })
  
    if (response.status === 200) {
      return response.text();
    }
  
    throw response;
  }