import React from 'react';
import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';

const Button = ({ onClick, children, loading, title }) => {
    return (
        <button title={title} onClick={() => { onClick() }} className="h-11 w-11 rounded-[22px] bg-airpark-green flex justify-center items-center">
           {!loading && children}

           {loading &&
                <Lottie className="h-5 w-5" animationData={animSpinnerWhite} loop={true} />
           }
        </button>
    );
}

export default Button;